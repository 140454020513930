import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Col, Container, Row} from "react-bootstrap";
import {
    FaThumbsUp
} from "react-icons/fa";
import Card from "react-bootstrap/Card";

const ReviewsPage = () => (
    <Layout pageInfo={{pageName: "reviews"}}>
        <SEO title="Services Offered Massages by Jooley"/>

        <Container>
            <Row>
                <Col lg={true}>
                    <h1><FaThumbsUp/> Testimonials</h1>
                    <p className="font-weight-lighter font-italic">
                        <h4>Client's Wellness Reviews</h4>
                    </p>
                    <hr/>
                </Col>
            </Row>
        </Container>

        <Container>
            <Row>
                <Col>

                    <Card>
                        <Card.Header>Testimonials</Card.Header>
                        <Card.Body>
                            <blockquote className="blockquote mb-0">
                                <p>
                                    {' '}
                                    "Wow I've never been on a journey quite like that before"{' '}
                                </p>
                                <footer className="blockquote-footer">
                                    <cite title="Source Title">Happy Client</cite>
                                </footer>
                            </blockquote>

                            <br/>
                            <blockquote className="blockquote mb-0">
                                <p>
                                    {' '}
                                    "Your massages are like snowflakes, different every time but always amazing."{' '}
                                </p>
                                <footer className="blockquote-footer">
                                    <cite title="Source Title">Happy Client</cite>
                                </footer>
                            </blockquote>

                            <br/>
                            <blockquote className="blockquote mb-0">
                                <p>
                                    {' '}
                                    "You sure know how to find all the spots!"{' '}
                                </p>
                                <footer className="blockquote-footer">
                                    <cite title="Source Title">Happy Client</cite>
                                </footer>
                            </blockquote>
    
                            <br/>
                            <blockquote className="blockquote mb-0">
                                <p>
                                    {' '}
                                    "It's like a powerwasher for tension!"{' '}
                                </p>
                                <footer className="blockquote-footer">
                                    <cite title="Source Title">Chris A</cite>
                                </footer>
                            </blockquote>
    
                        </Card.Body>
                    </Card><br/>

                </Col>
            </Row>
        </Container>

    </Layout>
);

export default ReviewsPage
